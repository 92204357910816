/**
 * Footer component
 */
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import { paths } from "shared/constants";
import facebook from "./facebook.svg";
import logo from "./gmri-logo-gray.svg";
import instagram from "./instagram.svg";
import twitter from "./twitter.svg";
import youtube from "./youtube.svg";

/**
 * Footer component
 */
export const Footer: React.FC = () => <Container fluid={true} className="page-footer" data-sentry-element="Container" data-sentry-component="Footer" data-sentry-source-file="index.tsx">
    <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
      <Col md={{
      size: 5,
      offset: 1
    }} xs={{
      size: 10,
      offset: 1
    }} className="text-center logo" data-sentry-element="Col" data-sentry-source-file="index.tsx">
        <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <Col className="gmri-logo-gray" data-sentry-element="Col" data-sentry-source-file="index.tsx">
            <a href={paths.social.gmri}>
              <Image src={logo} alt="Gulf of Maine Research Institute" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
            </a>
          </Col>
        </Row>
        <Row className="small-p" data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <Col data-sentry-element="Col" data-sentry-source-file="index.tsx">
            <p>350 Commercial St.</p>
            <p>Portland, Maine 04101</p>
          </Col>
          <Col data-sentry-element="Col" data-sentry-source-file="index.tsx">
            <p>
              <a href="tel:1-207-772-2121">207-772-2321</a>
            </p>
            <p>
              <a href="mailto:EcosystemInvestigation@gmri.org">EcosystemInvestigation@gmri.org</a>
            </p>
          </Col>
        </Row>
        <Row className="social" data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <Col data-sentry-element="Col" data-sentry-source-file="index.tsx">
            <a href={paths.social.facebook} target="_blank" rel="noopener noreferrer">
              <Image src={facebook} className="facebook" alt="GMRI on Facebook" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
            </a>
          </Col>
          <Col data-sentry-element="Col" data-sentry-source-file="index.tsx">
            <a href={paths.social.twitter} target="_blank" rel="noopener noreferrer">
              <Image src={twitter} className="twitter" alt="GMRI on Twitter" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
            </a>
          </Col>
          <Col data-sentry-element="Col" data-sentry-source-file="index.tsx">
            <a href={paths.social.instagram} target="_blank" rel="noopener noreferrer">
              <Image src={instagram} className="instagram" alt="GMRI on Instagram" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
            </a>
          </Col>
          <Col data-sentry-element="Col" data-sentry-source-file="index.tsx">
            <a href={paths.social.youtube} target="_blank" rel="noopener noreferrer">
              <Image src={youtube} className="youtube" alt="GMRI on YouTube" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
            </a>
          </Col>
        </Row>
        <Row className="small-p" data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <Col data-sentry-element="Col" data-sentry-source-file="index.tsx">
            <Link href={paths.privacyPolicy} data-sentry-element="Link" data-sentry-source-file="index.tsx">Privacy</Link>
            {" | "}
            <Link href={paths.userAgreement} data-sentry-element="Link" data-sentry-source-file="index.tsx">User Agreement</Link>
          </Col>
        </Row>
      </Col>

      <Col md={{
      size: 4,
      offset: 1
    }} xs={{
      size: 10,
      offset: 1
    }} data-sentry-element="Col" data-sentry-source-file="index.tsx">
        <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <Col sm={6} md={3} lg={2} data-sentry-element="Col" data-sentry-source-file="index.tsx">
            <p>
              <Link href={paths.home} data-sentry-element="Link" data-sentry-source-file="index.tsx">Home</Link>
            </p>
            <p>
              <Link href={paths.about} data-sentry-element="Link" data-sentry-source-file="index.tsx">About</Link>
            </p>
            <p>
              <Link href={paths.faq} data-sentry-element="Link" data-sentry-source-file="index.tsx">FAQ</Link>
            </p>
          </Col>

          <Col data-sentry-element="Col" data-sentry-source-file="index.tsx">
            <p>
              <Link href={paths.missions.forIndividuals} data-sentry-element="Link" data-sentry-source-file="index.tsx">Projects for Individuals</Link>
            </p>
            <p>
              <Link href={paths.missions.forGroups} data-sentry-element="Link" data-sentry-source-file="index.tsx">Projects for Groups</Link>
            </p>

            <p>
              <Link href={paths.partners} data-sentry-element="Link" data-sentry-source-file="index.tsx">Partners</Link>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>;