"use client";

import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { HeaderBlock } from "components/Blocks/Header";
import { Footer } from "components/Footer";
import NavBar from "components/NavBar";
const image = {
  rendition: {
    url: "https://citsci-beta-media.sfo2.cdn.digitaloceanspaces.com/manual-static/sunset.jpeg"
  }
};
export const FiveHundredInner: React.FC = () => <React.Fragment>
    <HeaderBlock h1={"500: End of Adventure"} h2={"Something did not go as planned"} image={image} darkBackground={true} data-sentry-element="HeaderBlock" data-sentry-source-file="global-error.tsx" />
    <Row style={{
    paddingTop: "1rem",
    paddingBottom: "1rem"
  }} data-sentry-element="Row" data-sentry-source-file="global-error.tsx">
      <Col xs={{
      offset: 1,
      size: 10
    }} md={{
      offset: 2,
      size: 8
    }} data-sentry-element="Col" data-sentry-source-file="global-error.tsx">
        <Row data-sentry-element="Row" data-sentry-source-file="global-error.tsx">
          <Col data-sentry-element="Col" data-sentry-source-file="global-error.tsx">
            <h5>There was an error with the site.</h5>
            <p>
              This was definately not the adventure we planned for you. We do our best to make sure
              everything works perfectly, but like any good adventure there are always a few
              suprises.
            </p>
            <p>
              If you could fill out the form that pops up with as much detail as you can, that would
              be incredibly useful to use to try to keep it from breaking in the future.
            </p>
            <p>
              If you think of anything later that might help us fix the issue, please email{" "}
              <a href="mailto:EcosystemInvestigation@gmri.org">EcosystemInvestigation@gmri.org</a>{" "}
              with additional information.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  </React.Fragment>;
export default function GlobalError({
  error
}: {
  error: Error;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <div className="App" id="500_page">
          <NavBar data-sentry-element="NavBar" data-sentry-source-file="global-error.tsx" />
          <FiveHundredInner data-sentry-element="FiveHundredInner" data-sentry-source-file="global-error.tsx" />
          <Footer data-sentry-element="Footer" data-sentry-source-file="global-error.tsx" />
        </div>
      </body>
    </html>;
}