/**
 * Full bleed header image
 */
import { gql } from "@apollo/client";
import Link from "next/link";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Block } from "./_block";

/** Full width header with background image */
export interface HeaderProps {
  /** Optional anchor tag */
  anchor?: string;
  /** Full width background header image */
  image: {
    rendition: {
      url: string;
    };
  };
  /** Primary header text */
  h1: string;
  /** Optional subheader */
  h2?: string;
  /** Optional link url */
  link?: string;
  /** Optional link button text */
  linkText?: string;
  /** Move header to the left when the image focus is to the right */
  rightLayout?: boolean;
  /** Invert text colors if the background is dark */
  darkBackground: boolean;
}
export type Header = HeaderProps & Block;

/**
 * Full bleed header image
 *
 * @param h1 Title
 * @param h2 Optional subtitle
 * @param rightLayout Should elements be aligned to the right
 * @param image Image URL to use
 * @param darkBackground Invert text colors if image background is dark
 */
export const HeaderBlock: React.FC<HeaderProps> = ({
  h1,
  h2,
  rightLayout,
  image,
  darkBackground,
  link,
  linkText
}: HeaderProps) => <Row style={{
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("' + image.rendition.url + '")',
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "bottom right",
  textAlign: !rightLayout ? "right" : "left"
}} className="header-block" data-sentry-element="Row" data-sentry-component="HeaderBlock" data-sentry-source-file="Header.tsx">
    <Col xs={rightLayout ? {
    size: 6,
    offset: 1
  } : {
    size: 6,
    offset: 5
  }} style={{
    paddingTop: "1rem",
    paddingBottom: "1rem"
  }} data-sentry-element="Col" data-sentry-source-file="Header.tsx">
      <h1 style={{
      color: darkBackground ? "white" : "black"
    }}>{h1}</h1>
      {h2 ? <h3 style={{
      color: darkBackground ? "white" : "black"
    }}>{h2}</h3> : null}
      {link && linkText ? <Link href={link}>
          <Button>{linkText}</Button>
        </Link> : null}
    </Col>
  </Row>;

/**
 * GraphQL fragment for quering Header Blocks
 *
 * ... on HeaderBlock {
 *  ...HeaderBlockFragment
 * }
 */
export const HeaderBlockFragment = gql`
  fragment HeaderBlockFragment on HeaderBlock {
    blockType
    anchor
    image {
      rendition(fill: "1200x500") {
        url
      }
    }
    h1
    h2
    link
    linkText
    rightLayout
    darkBackground
  }
`;